import { useState } from 'react'
import { BannerType, FoxBanner } from 'shared/src/components/banner/FoxBanner'
import { FoxTextH4 } from 'shared/src/components/typography/FoxText'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'
import { sprintf } from 'sprintf-js'
import { ImageUploadForm } from 'installation_images/src/ImageUploadForm'
import type { ResultState } from 'installation_images/src/util/types'

type Props = {
  hwid: string
}

export const ImageUploadPage = ({ hwid }: Props) => {
  const [resultState, setResultState] = useState<ResultState>({ imagesUploaded: undefined })

  return isValue(resultState.imagesUploaded) ? (
    <div>
      {resultState.imagesUploaded > 0 ? (
        <FoxBanner
          type={BannerType.SUCCESS}
          content={sprintf(T('image_successfully_uploaded'), resultState.imagesUploaded)}
        />
      ) : null}
      <FoxTextH4>{T('page_close_return_app')}</FoxTextH4>
    </div>
  ) : (
    <ImageUploadForm hwid={hwid} setResultState={setResultState} />
  )
}
