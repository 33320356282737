/* eslint-disable react/jsx-props-no-spreading */
import type { BackdropProps, CircularProgressProps } from '@mui/material'
import { Backdrop, CircularProgress, Container } from '@mui/material'
import 'shared/src/components/loader/FoxLoader.scss'

type Props = Omit<BackdropProps, 'open'> & Pick<CircularProgressProps, 'size' | 'thickness' | 'color'>

/*
 * The component using this FoxLoader needs to have a fixed height value
 * for the loader to be centered vertically
 */
export const FoxLoader = ({ ...props }: Props) => {
  return (
    <Container className='fox-loader' disableGutters>
      <Backdrop open {...props}>
        <CircularProgress
          className={props.className}
          size={props.size}
          thickness={props.thickness}
          color={props.color}
        />
      </Backdrop>
    </Container>
  )
}
