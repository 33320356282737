import * as Sentry from '@sentry/react'
import { jwtDecode } from 'jwt-decode'
import { FoxTextH3 } from 'shared/src/components/typography/FoxText'
import { useAnalytics } from 'shared/src/context/AnalyticsContext'
import { WrapperProvider } from 'shared/src/context/WrapperProvider'
import { Regex } from 'shared/src/util/regex'
import { ANALYTICS_CATEGORY } from 'shared/src/util/trackingHelpers'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'
import { CommonUrlQueryKeys } from 'shared/src/util/url'
import { ImageUploadPage } from 'installation_images/src/ImageUploadPage'
import { UrlQueryKeys } from 'installation_images/src/util/url'
import 'installation_images/src/App.scss'

const App = () => {
  const { AnalyticsEvent } = useAnalytics()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const hwid = urlSearchParams.get(CommonUrlQueryKeys.HWID)
  let token: string | undefined = urlSearchParams.get(UrlQueryKeys.TOKEN) ?? ''

  try {
    token = jwtDecode(token)
  } catch (_) {
    AnalyticsEvent('Invalid page visit: Invalid token')
    token = undefined
  }

  const isVisitValid = isValue(token) && isValue(hwid) && Regex.hwid.test(hwid)

  if (isVisitValid) {
    AnalyticsEvent('Page visit', hwid)
  } else {
    AnalyticsEvent('Invalid page visit: Invalid HWID')
  }

  return (
    <WrapperProvider analyticsCategory={ANALYTICS_CATEGORY.INSTALLATION_IMAGES}>
      <div className='app'>
        {isVisitValid ? <ImageUploadPage hwid={hwid} /> : <FoxTextH3>{T('unsupported_request')}</FoxTextH3>}
      </div>
    </WrapperProvider>
  )
}
export default Sentry.withProfiler(App)
