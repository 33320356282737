import type { AxiosError } from 'axios'

export type FoxError = {
  errorCode: string
  statusCode: number
}

export type FoxErrorResponse = AxiosError<FoxError>

export const getStatusCode = (error: FoxErrorResponse): number => {
  return error.response?.data.statusCode ?? error.response?.status ?? 0
}

export const getErrorCode = (error: FoxErrorResponse): string | undefined => {
  return error.response?.data.errorCode
}
