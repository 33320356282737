import * as Sentry from '@sentry/react'
import { useMutation } from '@tanstack/react-query'
import type { Dispatch, SetStateAction } from 'react'
import { useAnalytics } from 'shared/src/context/AnalyticsContext'
import type { FoxErrorResponse } from 'shared/src/util/error'
import { getStatusCode } from 'shared/src/util/error'
import { T } from 'shared/src/util/translation'
import type { DtoInstallationImagesRequest } from 'installation_images/src/dto/request'
import { httpClient } from 'installation_images/src/service/http/HttpClient'
import type { ResultState } from 'installation_images/src/util/types'

export const useMutationUploadInstallationImages = (
  setError: Dispatch<SetStateAction<string>>,
  setResultState: Dispatch<SetStateAction<ResultState>>,
) => {
  const { AnalyticsEvent } = useAnalytics()
  return useMutation({
    mutationFn: async (request: DtoInstallationImagesRequest) => {
      const { data } = await httpClient.uploadInstallationImages(request)
      return data
    },
    onSuccess: (_, request) => {
      AnalyticsEvent('Upload success', request.hwid)
      setResultState({ imagesUploaded: request.images.length })
      window.scrollTo(0, 0)
    },
    onError: (error: FoxErrorResponse, request) => {
      Sentry.captureException(error)
      AnalyticsEvent('Upload error', request.hwid)
      const statusCode = getStatusCode(error)
      setResultState({ imagesUploaded: undefined })

      switch (statusCode) {
        case 401:
          setError(T('httpError_401'))
          break
        case 403:
          setError(T('get_device_403'))
          break
        default:
          setError(T('something_went_wrong'))
          break
      }
    },
  })
}
