import Button from '@mui/material/Button'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { BannerType, FoxBanner } from 'shared/src/components/banner/FoxBanner'
import { ImageUpload } from 'shared/src/components/imageUpload/ImageUpload'
import { FileSizes } from 'shared/src/components/imageUpload/types'
import { FoxLoader } from 'shared/src/components/loader/FoxLoader'
import { FoxTextBody1 } from 'shared/src/components/typography/FoxText'
import { useAnalytics } from 'shared/src/context/AnalyticsContext'
import { T } from 'shared/src/util/translation'
import { isValueAndNotEmptyString } from 'shared/src/util/typeGuard'
import { toRequest } from 'installation_images/src/dto/request'
import { useMutationUploadInstallationImages } from 'installation_images/src/mutations/useMutationUploadInstallationImages'
import { useImageUploadReducer } from 'installation_images/src/reducer/imageUploadReducer'
import { ImageGroup } from 'installation_images/src/util/types'
import type { ResultState } from 'installation_images/src/util/types'

type Props = {
  hwid: string
  setResultState: Dispatch<SetStateAction<ResultState>>
}

export const ImageUploadForm = ({ hwid, setResultState }: Props) => {
  const { AnalyticsEvent } = useAnalytics()

  const [error, setError] = useState('')
  const [imagesState, dispatch] = useImageUploadReducer()
  const uploadMutation = useMutationUploadInstallationImages(setError, setResultState)

  const areNoImagesAdded = Object.values(imagesState.images).flat().length === 0

  const buttonLabel = areNoImagesAdded ? T('skip') : T('upload_pictures')

  return (
    <>
      <div>
        <FoxTextBody1 className='installation_images_upload_description'>
          {T('installation_image_upload_description')}
        </FoxTextBody1>

        {Object.values(ImageGroup).map((it) => (
          <ImageUpload
            key={it}
            maxImages={3}
            maxFileSizeByte={FileSizes.SIZE_2_MB}
            uploadActionLabel={T(`installation_image_upload_${it}`)}
            buttonLabel='take_or_upload_picture'
            images={imagesState.images[it]}
            updateFormState={(v) => {
              setError('')
              dispatch({
                type: it,
                images: v,
              })
            }}
          />
        ))}
      </div>
      <div>
        {isValueAndNotEmptyString(error) ? <FoxBanner type={BannerType.WARNING} content={error} /> : null}

        <Button
          disabled={uploadMutation.isPending}
          variant='contained'
          fullWidth
          onClick={() => {
            if (!areNoImagesAdded) {
              uploadMutation.mutate(toRequest(imagesState))
            } else {
              AnalyticsEvent('Upload skipped', hwid)
              setResultState({ imagesUploaded: 0 })
              window.scrollTo(0, 0)
            }
          }}
        >
          {uploadMutation.isPending ? <FoxLoader size={32} invisible /> : <span>{buttonLabel}</span>}
        </Button>
      </div>
    </>
  )
}
