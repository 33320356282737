import type { PictureUpload } from 'shared/src/components/imageUpload/types'
import { dataURLtoFile } from 'shared/src/util/blobUtil'
import { CommonUrlQueryKeys } from 'shared/src/util/url'
import type { ImageUploadStateType } from 'installation_images/src/reducer/imageUploadReducer'
import type { ImageGroup } from 'installation_images/src/util/types'

export interface DtoInstallationImagesRequest {
  hwid: string
  images: PictureUpload[]
}

export const toRequest = (formState: ImageUploadStateType) => {
  const hwid = new URLSearchParams(window.location.search).get(CommonUrlQueryKeys.HWID) ?? ''
  return {
    hwid,
    images: Object.keys(formState.images).reduce((acc: PictureUpload[], curr) => {
      const group = curr as ImageGroup
      const groupImages = formState.images[group].map((it) => {
        return {
          name: `${group}_${Date.now()}.${it.file.name.split('.').pop()}`,
          bytes: dataURLtoFile(it.dataURL, it.file),
        }
      })

      return acc.concat(groupImages)
    }, []),
  }
}
