export enum ImageGroup {
  DEVICE = 'DEVICE',
  OVERVIEW = 'OVERVIEW',
  OPENING = 'OPENING',
  NAMEPLATE = 'NAMEPLATE',
  OTHER = 'OTHER',
}

export type ResultState = {
  imagesUploaded: number | undefined
}
