/* eslint-disable no-param-reassign */
import type { Dispatch } from 'react'
import type { StrictImageType } from 'shared/src/components/imageUpload/types'
import { useImmerReducer } from 'use-immer'
import { ImageGroup } from 'installation_images/src/util/types'

export type ImageUploadStateType = {
  images: Record<ImageGroup, StrictImageType[]>
}

const initialImagesUploadState: ImageUploadStateType = {
  images: {
    [ImageGroup.DEVICE]: [],
    [ImageGroup.OVERVIEW]: [],
    [ImageGroup.OPENING]: [],
    [ImageGroup.NAMEPLATE]: [],
    [ImageGroup.OTHER]: [],
  },
}

export const ImageGroupToAction: Record<ImageGroup, string> = {
  [ImageGroup.DEVICE]: 'SET_DEVICE_IMAGES',
  [ImageGroup.OVERVIEW]: 'SET_OVERVIEW_IMAGES',
  [ImageGroup.OPENING]: 'SET_OPENING_IMAGES',
  [ImageGroup.NAMEPLATE]: 'SET_NAMEPLATE_IMAGES',
  [ImageGroup.OTHER]: 'SET_OTHER_IMAGES',
}

export type ImageUploadStateAction = {
  type: ImageGroup
  images: StrictImageType[]
}

const imageReducer = (draft: ImageUploadStateType, action: ImageUploadStateAction): ImageUploadStateType => {
  draft.images[action.type] = action.images
  return draft
}

export const useImageUploadReducer = (): [ImageUploadStateType, Dispatch<ImageUploadStateAction>] => {
  const [state, dispatch] = useImmerReducer(imageReducer, initialImagesUploadState)
  return [state, dispatch]
}
