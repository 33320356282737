import type { AxiosResponse } from 'axios'
import { BaseHttpClient } from 'shared/src/service/http/BaseHttpClient'
import { isValue } from 'shared/src/util/typeGuard'
import type { DtoInstallationImagesRequest } from 'installation_images/src/dto/request'
import { UrlQueryKeys } from 'installation_images/src/util/url'

class HttpClient extends BaseHttpClient {
  constructor() {
    super()

    this.client.interceptors.request.use((config) => {
      const authToken = new URLSearchParams(window.location.search).get(UrlQueryKeys.TOKEN)
      if (isValue(authToken) && isValue(config.headers)) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${authToken}`
      }
      return config
    })
  }

  uploadInstallationImages(request: DtoInstallationImagesRequest): Promise<AxiosResponse<void>> {
    const { hwid, images } = request
    const formData = new FormData()
    for (const img of images) {
      formData.append('images', img.bytes, img.name)
    }
    return this.client.post(`/device/${hwid}/installation-images`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  }
}

export const httpClient = new HttpClient()
